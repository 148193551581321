import React from 'react';
import styled from 'styled-components';
import { compose, flexbox, height, maxWidth, space } from  'styled-system';
import propTypes from '@styled-system/prop-types';

const style = compose(
    flexbox,
    height,
    maxWidth,
    space
);

const Flexbox = styled.div`

    display: flex;

    ${style}
`;

Flexbox.propTypes = {

    ...propTypes.flexbox,
    ...propTypes.height,
    ...propTypes.maxWidth,
    ...propTypes.space
};

const MemoizedFlexbox = React.memo(Flexbox);

MemoizedFlexbox.defaultProps = {

    justifyContent: 'flex-start',
    alignItems: 'center'
};

export default MemoizedFlexbox;