import React, { useMemo } from 'react';
import { allPass, complement, has, isNil } from 'ramda';
import {
	borderRadius,
	color,
	compose,
	fontFamily,
	fontSize,
	fontWeight,
	space,
	textTransform,
	layout,
} from 'styled-system';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import defaultTheme from '../../utils/themes';
import propTypes from '@styled-system/prop-types';

const isFontAwesomeIcon = allPass([
	complement(isNil),
	has('prefix'),
	has('iconName'),
	has('icon'),
]);

export const IconWrapper = styled.div`
	/* height: auto; */
	padding: 0 8px 0 0;
	box-sizing: border-box;

	cursor: pointer;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	img {
		margin-bottom: 0;
	}
`;

const style = compose(
	borderRadius,
	color,
	fontFamily,
	fontSize,
	fontWeight,
	space,
	textTransform,
	layout
);

export const ButtonContainer = styled.button`
	position: relative;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	padding: 8px;
	overflow: hidden;
	border: none;

	text-align: center;

	cursor: pointer;
	transition: 0.3s;

	text-decoration: none;
	${style} :hover {
		${({ backgroundColor, theme }) => {
			const color =
				backgroundColor === 'primary'
					? theme.colors['primary-hover']
					: theme.colors['secondary-hover'];
			return css`
				background-color: ${color};
			`;
		}}
	}

	:disabled {
		background: #4b4b4b;
		cursor: not-allowed;
	}
`;

const Button = ({
	type,
	disabled,
	onClick,
	children,
	icon,
	IconWrapperComponent: IconWrapper,
	...otherProps
}) => {
	IconWrapper;
	const iconInstance = useMemo(() => {
		if (isFontAwesomeIcon(icon)) {
			return (
				<IconWrapper>
					<FontAwesomeIcon icon={icon} />
				</IconWrapper>
			);
		}

		if (React.isValidElement(icon)) {
			return <IconWrapper>{icon}</IconWrapper>;
		}

		return null;
	}, [icon]);
	return (
		<ButtonContainer
			{...otherProps}
			onClick={onClick}
			type={type}
			disabled={disabled}
		>
			{iconInstance}
			<span>{children}</span>
		</ButtonContainer>
	);
};

const fontAwesomeIconPropType = PropTypes.shape({
	prefix: PropTypes.string.isRequired,
	iconName: PropTypes.string.isRequired,
	icon: PropTypes.array,
});

Button.propTypes = {
	...propTypes.borderRadius,
	...propTypes.color,
	...propTypes.fontFamily,
	...propTypes.fontSize,
	...propTypes.fontWeight,
	...propTypes.height,
	...propTypes.lineHeight,
	...propTypes.space,
	...propTypes.textTransform,
	...propTypes.width,

	children: PropTypes.node.isRequired,
	icon: PropTypes.oneOfType([fontAwesomeIconPropType, PropTypes.element]),
	IconWrapperComponent: PropTypes.elementType.isRequired,
	theme: PropTypes.object,
};

Button.defaultProps = {
	borderRadius: 'slight',
	backgroundColor: 'primary',
	color: 'white',
	fontFamily: 'primary',
	fontSize: '15px',
	fontWeight: '600',
	letterSpacing: '.025em',
	IconWrapperComponent: IconWrapper,
	lineHeight: 'auto',
	paddingLeft: 0,
	paddingRight: 0,
	textTransform: 'uppercase',
	theme: defaultTheme,
};

export default Button;
