import styled from 'styled-components';
import {
  color,
  compose,
  layout,
  space,
  textTransform,
  typography,
} from 'styled-system';
import propTypes from '@styled-system/prop-types';

const style = compose(color, layout, space, textTransform, typography);

const Typography = styled.p(style);

Typography.propTypes = {
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.space,
  ...propTypes.textTransform,
  ...propTypes.typography,
};

export const Heading1 = styled(Typography)``;

Heading1.defaultProps = {
  as: 'h1',
};

export const Heading2 = styled(Typography)``;

Heading2.defaultProps = {
  as: 'h2',
};

export const Heading3 = styled(Typography)``;

Heading3.defaultProps = {
  as: 'h3',
};

export const Heading4 = styled(Typography)``;

Heading4.defaultProps = {
  as: 'h4',
};

export const Heading5 = styled(Typography)``;

Heading5.defaultProps = {
  as: 'h5',
};

export const Heading6 = styled(Typography)``;

Heading6.defaultProps = {
  as: 'h6',
};

export const Paragraph = styled(Typography)``;

export const Span = styled(Typography)``;

Span.defaultProps = {
  as: 'span',
};

export const Bold = styled(Typography).attrs({ as: 'b' })``;

Bold.defaultProps = {
  as: 'b',
};

export default Typography;
