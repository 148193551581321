import { flexbox, background, color, height, space } from 'styled-system';
import styled, { css } from 'styled-components';

import Container from '../Container';
import React from 'react';
import lowPolyBackground from '../../images/low-poly-pattern.png';

const customBackground = (props) => {
    if (props.background !== 'diamond') return background(props);

    return css`
		background-image: url(${lowPolyBackground});
		background-size: cover;
		background-repeat: no-repeat;
	`;
};

export const SectionWrapper = styled.section`
	max-width: 100%;
	width: 100%;
	min-height: 35px;

	${color}
	${customBackground}
    ${height}
    ${space}
    ${flexbox}
`;

const Section = ({ children, theme, ...otherProps }) => (
	<SectionWrapper theme={theme} {...otherProps}>
		<Container theme={theme}>{children}</Container>
	</SectionWrapper>
);

Section.propTypes = {
	...background.propTypes,
	...color.propTypes,
};

Section.defaultProps = {
	backgroundColor: 'white',
};

export default Section;
